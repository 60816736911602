import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useGetMediaQueryDown } from "../hooks/getMediaQurey";
import { city, city2 } from "../constant/company";
import FacebookIcon from "@mui/icons-material/Facebook";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export const Footer = () => {
  const isSmDown = useGetMediaQueryDown("sm");

  return (
    <Box
      sx={{
        backgroundColor: "#141416",
      }}
    >
      <Box
        display="flex"
        gap={isSmDown ? 2 : 0}
        flexDirection={isSmDown ? "column" : "row"}
        padding="10px 10px"
        justifyContent="space-around"
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Box component="img" src="logo/logo2.png" height={60} width="auto" />
          <Box display="flex" gap={1} alignItems="center">
            <FmdGoodIcon sx={{ color: "#FF9933" }} />
            <Typography color="#FFF">
              72, Dwarkesh Industrial estate, Vibhag-1,<br></br> Olpad, Hajira
              Velanja Ring Road, Surat ,Gujarat-394150
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <AddIcCallIcon sx={{ color: "#0B76BA" }} />
            <Link
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#FFF",
              }}
              href="tel:+918866472206"
            >
              +91 88664 72206
            </Link>
          </Box>

          <Box display="flex" gap={1} alignItems="center">
            <MailOutlineIcon sx={{ color: "#FF9933" }} />
            <Link
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#FFF",
              }}
              href="mailto:accounts@lrvpetrochem.in"
            >
              accounts@lrvpetrochem.in
            </Link>
          </Box>
        </Box>

        <Box
          marginTop={isSmDown ? 0 : "40px"}
          marginLeft={isSmDown ? 0 : "25px"}
        >
          <Typography color="#FFF" fontSize="22px">
            Locations
          </Typography>
          <Box display="flex" flexDirection="row">
            <Box component="ul" sx={{ m: 1, pl: 2, color: "#FFF" }}>
              {city.map((city, index) => (
                <li key={index} color="#FFF">
                  {city}
                </li>
              ))}
            </Box>
            <Box component="ul" sx={{ m: 1, pl: 2, color: "#FFF" }}>
              {city2.map((city, index) => (
                <li key={index} color="#FFF">
                  {city}
                </li>
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          marginTop={isSmDown ? 0 : "40px"}
          marginLeft={isSmDown ? 0 : "25px"}
        >
          <Typography color="#FFF" fontSize="22px">
            Follow Us
          </Typography>
          <Box
            component="a"
            href="https://www.facebook.com/people/LRV-petrochem/61555875915034/"
            target="_blank"
          >
            <FacebookIcon sx={{ color: "#0866FF" }} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: "#fc9733", height: "3px", width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isSmDown ? "column" : "row",
        }}
      >
        <Typography color="#FFF" padding="10px" fontSize="12px">
          © Copyright 2024. Developed & Maintained by LRV Petrochem
        </Typography>
        <Box
          component={"a"}
          href="/privacy-policy"
          sx={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
          mr={4}
        >
          <Typography
            variant="body1"
            align="center"
            color="#FFF"
            fontSize="12px"
            padding="10px"
          >
            Privacy & Policy
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
