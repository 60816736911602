import { Container, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Zoom, Autoplay } from "swiper/modules";
import { companyConatant } from "../constant/company";
import { useGetMediaQueryDown } from "../hooks/getMediaQurey";
import { Box } from "@mui/material";
import { Header } from "./../Header/index";
import { Footer } from "./../Footer/index";

import "swiper/css";

export const AboutUs = () => {
  const swiperRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [_, setSwiperIndex] = useState(0);
  const isSmDown = useGetMediaQueryDown("sm");
  const isMdown = useGetMediaQueryDown("md");

  return (
    <>
      <Header />
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Box sx={{ marginBottom: "50px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "0px",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h1" fontSize="45px" marginTop="10px">
                About Us
              </Typography>
              <Box
                sx={{
                  height: "2px",
                  width: "100px",
                  background: "#0b679f",
                  marginBottom: "20px",
                }}
              />
            </Box>
            <Box padding="10px">
              <Typography>
                LRV Petrochem is AI Tech based Polymer Distributor, having more
                than 2 decade of experience of Plastic processing and more than
                5 year in polymer distribution. Our platform allows plastic
                manufacturer to procure raw material from wide range of grade at
                competitive price. We aim to make material sourcing process
                simple and hassle free.
              </Typography>
              &nbsp;
              <Typography>
                With our one click access platform, we help customers to get
                daily price update of variety of grades, industry trend & news,
                Govt Policy Information, International
              </Typography>
              &nbsp;
              <Typography>
                We deals in All type of Polymers Like &nbsp;
                <b>PP, LLDPE, LDPE, HDPE, HM, PET, PVC, EVA, GPPS, HIPS</b>.
              </Typography>
              &nbsp;
              <Typography>
                At LRV Petrochem, we take pride in being at the forefront of the
                polymer industry. Our team of experts is dedicated to sourcing
                and distributing high-performance polymers that fuel innovation
                in various technological applications. With a customer-centric
                approach, we strive to understand your unique needs and provide
                tailored solutions. Discover how our commitment to quality,
                reliability, and advanced materials sets us apart in the dynamic
                world of polymer distribution.
              </Typography>
              <Typography>
                Welcome to LRV Petrochem, a leading force in polymer
                distribution. We specialize in delivering cutting-edge polymer
                solutions, driving technological advancements across industries.
                With a commitment to excellence and innovation, we aim to be
                your preferred partner in material solutions. Explore our
                diverse portfolio and experience the power of forward-looking
                technology in polymer distribution.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0px",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "50px",
              padding: "10px",
            }}
          >
            <Typography variant="h2" fontSize="45px">
              Why Choose Us
            </Typography>
            <Box
              sx={{
                height: "2px",
                width: "100px",
                background: "#0b679f",
                marginBottom: "20px",
              }}
            />
            <Box
              display="flex"
              gap={1}
              flexDirection={isSmDown || isMdown ? "column" : "row"}
            >
              <Box
                sx={{
                  backgroundColor: "#ededed",
                  border: "1px solid",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "#fff",
                  },
                }}
              >
                <Typography fontSize="22px">
                  Global Reach, Local Expertise
                </Typography>
                <Typography>
                  With a widespread network, we offer global reach coupled with
                  localized expertise. Our team understands regional market
                  dynamics, ensuring you receive personalized support and
                  solutions wherever you operate.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#ededed",
                  border: "1px solid",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "#fff",
                  },
                }}
              >
                <Typography fontSize="22px">
                  Cutting-Edge Technologies
                </Typography>
                <Typography>
                  Embracing the latest advancements, we stay ahead of industry
                  trends to bring you state-of-the-art polymers. Our commitment
                  to staying technologically current empowers your projects with
                  materials that meet and exceed evolving standards.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#ededed",
                  border: "1px solid",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "#fff",
                  },
                }}
              >
                <Typography fontSize="22px">Sustainability Focus</Typography>
                <Typography>
                  We prioritize eco-friendly solutions, providing polymers that
                  align with sustainability goals. Explore a range of
                  environmentally conscious options without compromising on
                  performance, contributing to a greener and more responsible
                  future.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#ededed",
                  border: "1px solid",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "#fff",
                  },
                }}
              >
                <Typography fontSize="22px">
                  Collaborative Partnerships
                </Typography>
                <Typography>
                  Building lasting relationships with both clients and
                  suppliers, we foster collaboration. Our partnerships extend
                  beyond transactions, creating an ecosystem where innovation
                  and mutual success thrive.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#ededed",
                  border: "1px solid",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#032541",
                    color: "#fff",
                  },
                }}
              >
                <Typography fontSize="22px">
                  Responsive Customer Support
                </Typography>
                <Typography>
                  Experience seamless communication and swift responses with our
                  dedicated customer support team. We prioritize your queries
                  and concerns, ensuring a smooth and efficient experience
                  throughout our partnership.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "0px",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2" fontSize="45px">
              We Deal In
            </Typography>
            <Box
              sx={{
                height: "2px",
                width: "100px",
                background: "#0b679f",
                marginBottom: "20px",
              }}
            />
          </Box>

          <Box paddingBottom={10}>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              onActiveIndexChange={(val) => setSwiperIndex(val.activeIndex)}
              loop={true}
              slidesPerView={isSmDown ? 3 : 6}
              spaceBetween={2}
              modules={[Autoplay, Zoom, Navigation, Pagination]}
              className="mySwiper"
            >
              {companyConatant.map((val, index) => (
                <SwiperSlide key={index}>
                  <Box
                    component={"img"}
                    src={val.url}
                    height="100px"
                    width="100px"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};
