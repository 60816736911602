import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";

export const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#141416",
        height: "90px",
        display: "flex",
        alignItems: { sm: "center", md: "start" },
      }}
    >
      <Box
        maxWidth="xl"
        height={"100%"}
        sx={{
          display: "flex !important",
          alignItems: "center",
          paddingLeft: { sm: 0, md: "30px" },
        }}
      >
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src="logo/logo2.png"
              height={60}
              width="auto"
            />
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src="logo/logo2.png"
              height={60}
              width="auto"
            />
          </Typography>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
