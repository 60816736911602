import { Routes, Route } from "react-router-dom";
import { AboutUs } from "../../src/AboutUs/index";
import { PrivacyPolicy } from "../../src/PrivacyPolicy/index";

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<AboutUs />} />
      <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default AppRoutes;
