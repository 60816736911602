import { useMediaQuery, useTheme } from "@mui/material";

const useGetMediaQueryDown = (key) => {
  const theme = useTheme();
  const isSizeDown = useMediaQuery(theme.breakpoints.down(key));
  //   const isSizeDown = useMediaQuery((theme) => theme?.breakpoints?.down(key), {
  //     noSsr: true,
  //   });

  return isSizeDown;
};

export { useGetMediaQueryDown };
