export const companyConatant = [
  {
    url: "company/1.jpg",
  },
  {
    url: "company/2.jpg",
  },
  {
    url: "company/3.jpg",
  },
  {
    url: "company/4.jpg",
  },
  {
    url: "company/5.jpg",
  },
  {
    url: "company/6.jpg",
  },
  {
    url: "company/7.jpg",
  },
  {
    url: "company/8.jpg",
  },
  {
    url: "company/9.jpg",
  },
  {
    url: "company/10.jpg",
  },

  {
    url: "company/11.jpg",
  },
  {
    url: "company/12.jpg",
  },
];

export const pages = ["Products", "Pricing", "Blog"];

export const city = ["Bhiwandi", "Vapi", "Surat"];

export const city2 = ["Baroda", "Ahmedabad", "Mundra"];
